<template>
     <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 auth-con">

                 <div class="logo-site">
                     <img src="/theme/img/logo-dark.svg">
                 </div>
               <div class="authbox">
                   <h3>Sign In</h3>
                   <Form  id="signup" method="post" @submit="signInpHandle" v-if="!signInOk">
                       <div class="mb-3">
                            <label for="useremail" class="form-label">Email / Username</label>
                            <Field ref="useremail"  type="text" class="form-control" id="useremail" name="useremail" placeholder="Email or Username" v-model="model.useremail" :rules="validateEmail">
                            </Field>
                            <ErrorMessage name="useremail" />
                        </div>
                        <div class="mb-3">
                            <label for="userpassword" class="form-label">Password</label>
                            <Field  type="password" class="form-control" id="userpassword" name="userpassword" placeholder="password" v-model="model.userpassword" :rules="validatePwd">
                            </Field>
                             <ErrorMessage name="userpassword" />
                        </div>
                        <div class="mb-3">
                            <p v-html="model.status"></p>
                        </div>
                        <div class="mb-3">
                            <button class="btn" type="submit">Login</button>
                        </div>
                        <div class="mb-3 text-justify">
                            <a class="auth-nav" href="/auth/signup"> Don't have an account yet?</a> 
                        </div>
                        <div class="mb-3">
                            <a class="auth-nav" href="/auth/forgetpassword"> Forgot password?</a>
                        </div>
                        <div class="mb-3">
                            <a class="auth-nav" href="/"> Skip</a>
                        </div>
                   </Form >
                   <div class=""  v-else v-html="model.status">
                   </div>
               </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from 'axios'
export default {
    components: { Form, Field, ErrorMessage},
    data () {
        return {
            signInOk:false,
            model: {
                useremail: '',
                userpassword: '',
                status:''
            }
        }
    },
    mounted() {
       // if(this.$refs.useremail) this.$refs.useremail.$el.focus()
    },
    methods: {
        signInpHandle(values){
            var self=this;
            axios.post('/api/auth/signin', {
                userid:values.useremail.toLowerCase(),
                userpwd: values.userpassword
        }).then( async response => {
            self.signInOk = true
            self.model.status = `<p>${response.data.message}</p>`;
            this.$store.dispatch('regAuth', response.data.data).then(res => {
               window.location.href ="/feed"
            })
        }).catch(error=>{
        //console.log(error)
           self.model.status = error.response.data.message
        })},
        validateEmail(value) {
            if (!value) {   return 'Email or username is required';}
            // var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // if (!regex.test(value)) {   return 'invalid email';}
            return true;
        },
        validatePwd(value){
            if (!value) {   return 'Password is required';}
            // var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
            // if (!regex.test(value)) {   return 'Password min length 6 has at least one number and one special character';}
            return true;
        } 
    }
}
</script>